<template>
    <div class="content animate__animated animate__fadeIn" v-loading="isLoading">
        <div class="broadside">
            <div>
                <el-tree
                    :data="data"
                    node-key="id"
                    :props="{
                        label: 'categoryName'
                    }"
                    :highlight-current = "true"
                    :expand-on-click-node="false"
                    ref="treeList"
                    @node-click="handleNodeClick">
                </el-tree>
            </div>
        </div>
        <div>
            <div class="journalism_list" @click="skip(item,index)" v-for="(item,index) in list" :key="item.id">
                <div class="nr">
                    <img :src="item.coverImg" alt="" v-if="item.coverImg">
                    <div class="date_col">
                        <div class="day">{{ new Date(item.createTime).format('dd') }}</div>
                        <div class="years">{{ new Date(item.createTime).format('yyyy-MM') }}</div>
                    </div>
                    <div class="xw_nr">
                        <div class="bt">
                            {{item.title}}
                        </div>
                        <div class="slxq">
                            {{ item.synopsis }}
                            <span style="color: red;">[详情]</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 950px;display: flex;justify-content: center;margin: 200px 0;" v-if="list.length == 0">
                <img src="../assets/img/zwsj.png" alt="">
            </div>
            <div style="display: flex;flex-direction: row-reverse;margin-bottom: 20px;">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="current">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OfficialWebsiteParticulars',

    data () {
        return {
            data: [],
            titleIndex: 1,
            total: 0,
            isLoading: false,
            list: [],
            form: {
                categoryId: '',
                page: 1,
                limit: 10
            }
        };
    },
    props: {
        type: {
            type: Number
        }
    },

    methods: {
        handleNodeClick (data) {
            this.form.categoryId = data.id
            this.form.page = 1
            this.getNewList()
        },
        //新闻请求
        getNewList () {
            this.$axios.get("/yc/new/list", {
                params: this.form
            }).then(res => {
                if (res.data.code == '200') {
                    this.list = res.data.data.list
                    this.total = res.data.data.total
                }
            })
        },
        //侧边树形图加载
        getlistTree () {
            this.$axios.get("/yc/column/listTree", {
                params: {
                    type: this.type
                }
            }).then(res => {
                if (res.data.code == '200') {
                    this.data = res.data.data
                    this.$nextTick(() => {
                        this.$refs.treeList.setCurrentKey(this.data[0].id);
                        this.handleNodeClick(this.data[0].id)
                    }, 500);
                }
            })
        },
        //切换页数
        current (val) {
            this.form.page = val
            this.getNewList()
        },
        skip (item, index) {
            let query = {
                type: this.type,
                id: item.id,
                page: this.form.page,
                limit: this.form.limit,
                index: index,
                categoryId: this.form.categoryId,
                total: this.total
            }
            if (this.type == 1) {
                this.$router.push({ name: 'NewsBulletinParticulars', query: query });
            } else {
                this.$router.push({ name: 'tradingInformationParticulars', query: query });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.content {
    width: 1200px;
    box-sizing: border-box;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    min-height: 100vh;
    .broadside {
        margin: 0 20px 70px 0;
        width: 250px;
        background: #ffffff;
        box-shadow: 0px 2px 20px 0px #e9effa;
        /deep/ .el-tree-node__content {
            font-size: 14px;
            height: 54px;
            width: 100%;
            border-bottom: 1px solid #f8f5f5;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            text-align: center;
            line-height: 54px;
            cursor: pointer;
            &:hover {
                border-left: 4px solid #c50007;
                background: rgba(197, 0, 7, 0.05);
                color: #c50007;
            }
        }
    }
    .journalism_list {
        cursor: pointer;
        width: 950px;
        height: 140px;
        background: #fff;
        box-shadow: 0px 2px 20px 0px #e9effa;
        padding: 20px;
        margin-bottom: 20px;
        .nr {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
                width: 100px;
                height: 100px;
            }
            .date_col {
                width: 100px;
                padding: 0 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #999999;
                .day {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 45px;
                }
                .years {
                    font-size: 12px;
                }
            }
            .xw_nr {
                width: calc(100%);
                .bt {
                    font-size: 16px;
                    font-weight: 400;
                    color: #000000;
                    line-height: 25px;
                    margin-bottom: 10px;
                }
                .slxq {
                    font-size: 12px;
                    line-height: 28px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.4);
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
/deep/ .active {
    background-color: #c50007 !important;
}
/deep/
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    font-size: 14px;
    height: 54px;
    width: 100%;
    border-bottom: 1px solid #f8f5f5;
    border-right: 4px solid transparent;
    text-align: center;
    line-height: 54px;
    cursor: pointer;
    border-left: 4px solid #c50007;
    background: rgba(197, 0, 7, 0.05);
    color: #c50007;
}
</style>
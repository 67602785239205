<template>
    <div class="index">
        <!-- 头部导航组件 -->
        <navbar-header></navbar-header>

        <div class="box_title_right">
            <span class="el-icon-location-outline"></span><router-link to="/"> 首页 </router-link> / <span class="name">交易资讯</span> 
        </div>
        <particulars-list ref="particulars" :type="2"></particulars-list>
        <!-- 底部 -->
        <nav-footer></nav-footer>

    </div>
</template>

<script>
import NavbarHeader from "../components/home/newHeader.vue"
import NavFooter from "../components/common/NavFooter.vue"
import particularsList from "../components/particularsList.vue";
export default {
    components: {
        NavbarHeader,
        NavFooter,
        particularsList
    },
    data () {
        return {
            name: '顶部展示导航名称',
            columnList: [], // 栏目列表 
            isShowNews: false, // 是否展示新闻
        }
    },
    computed: {

    },
    mounted () {
        // this.queryList();
        this.$refs.particulars.getlistTree()
    },
    methods: {

    },
}
</script>
<style>
.el-dialog__wrapper {
    z-index: 100000000 !important;
}
.el-select-dropdown {
    z-index: 100000000000000000000000 !important;
}
.el-picker-panel {
    z-index: 100000000000000000000000 !important;
}
</style>

<style lang="scss" scoped>
.index {
    width: 100%;
    background: #f5f5f5;
    &::-webkit-scrollbar {
        display: none;
    }
    .name {
        font-size: 14px;
        color: #000;
    }
}
.box_title_right {
    width: 1200px;
    margin: 0 auto;
    font-size: 15px;
    color: #999999;
    padding: 20px 0;
    a {
        color: #999999;
    }
}
</style>